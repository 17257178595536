@import url('https://fonts.googleapis.com/css?family=Montserrat:300,500,700&subset=latin-ext');

html,
body,
#root {
	font-family: 'Montserrat', '"Helvetica Neue"', 'Arial', sans-serif;
	margin: 0;
	height: 100%;
	width: 100%;
	overflow: auto;
	padding-right: 0!important;
	position: relative;
}